<template>
  <div class="vasion-flex">
    <transition name="component-fade" mode="out-in">
      <router-view class="vasion-remaining-after-two-subnavs" />
    </transition>
  </div>
</template>

<script>

export default {
  name: 'TheAppDocumentGateway',
}
</script>
